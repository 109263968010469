import ls from '@livesession/sdk'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PrivacyConsentInterface, { PrivacyConsentsFieldEnum } from '../../interfaces/PrivacyConsentInterface'
import { updatePrivacyConsentsSuccess } from '../../services/global/global.actions'
import { State } from '../../services/reducers'
import { savePrivacyConsents } from '../../utils/privacyConsent'
import useChannelConfig from '../channelConfig/channelConfig'
import useAppEvents from '../events/events'
import { AppEventsEnum } from '../events/events.types'

const defaultConsents: PrivacyConsentInterface = {
  [PrivacyConsentsFieldEnum.REQUIRED]: true,
  [PrivacyConsentsFieldEnum.MARKETING]: true,
  [PrivacyConsentsFieldEnum.FUNCTIONAL]: true,
}

const usePrivacyConsent = (withEventTriggers: boolean = false) => {
  const privacyConsents = useSelector((state: State) => state.global.privacyConsents)
  const channelConfig = useChannelConfig()
  const dispatch = useDispatch()
  const { triggerEvent } = useAppEvents()

  useEffect(() => {
    if (withEventTriggers && channelConfig.current.consentBoxPopup) {
      const consents: PrivacyConsentInterface = {
        [PrivacyConsentsFieldEnum.REQUIRED]: true,
        [PrivacyConsentsFieldEnum.MARKETING]: privacyConsents?.[PrivacyConsentsFieldEnum.MARKETING] ?? false,
        [PrivacyConsentsFieldEnum.FUNCTIONAL]: privacyConsents?.[PrivacyConsentsFieldEnum.FUNCTIONAL] ?? false,
      }
      triggerEvent(AppEventsEnum.UPDATE_PRIVACY_CONSENTS, consents)
      triggerEvent(AppEventsEnum.UPDATE_PIXEL_FUNCTIONAL_CONSENT, consents)
    }
  }, [
    channelConfig.current.consentBoxPopup,
    privacyConsents?.[PrivacyConsentsFieldEnum.MARKETING],
    privacyConsents?.[PrivacyConsentsFieldEnum.FUNCTIONAL],
  ])

  const saveToStorage = (consents: PrivacyConsentInterface) => {
    savePrivacyConsents(consents)
    dispatch(updatePrivacyConsentsSuccess(consents))
    if (!consents[PrivacyConsentsFieldEnum.FUNCTIONAL]) {
      ls.optOut()
    }
  }

  return {
    default: defaultConsents,
    isPrivacyConsentRequired: channelConfig.current.consentBoxPopup,
    privacyConsents,
    saveConsents: saveToStorage,
  }
}

export default usePrivacyConsent
